<template>
  <nav :class="['metanavigation', { 'is-mobile': mobile }]" :aria-hidden="mobile">
    <div class="metanavigation-public">
      <slot/>
    </div>

    <div class="metanavigation-privat">
      <div class="user">
        <slot name="profile"/>
        <slot name="location"/>
      </div>

      <div class="hotline"><slot name="hotline"/></div>

      <div class="cart"><slot name="cart"/></div>
    </div>
  </nav>
</template>

<script>
export default {
  name: 'MetaNavigation',
  props: {
    mobile: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss">
// desktop
$metanavigation-padding-y: 0 !default;
$metanavigation-padding-x: $component-padding-x !default;
$metanavigation-height: $leaf-border-radius !default;
$metanavigation-border-radius: $leaf-border-radius !default;

$metanavigation-public-bg: $gray-100 !default;
$metanavigation-public-action-color: $black !default;
$metanavigation-public-action-hover-color: $primary !default;

$metanavigation-privat-bg: $primary !default;
$metanavigation-privat-action-color: theme-color-level('primary', -8) !default;
$metanavigation-privat-action-hover-color: $white !default;

$metanavigation-action-padding-y: 0 !default;
$metanavigation-action-padding-x: $spacer * 0.5 !default;
$metanavigation-action-font-weight: $font-weight-normal !default;
$metanavigation-action-font-size: $font-size-base * 0.625 !default;

$metanavigation-action-hover-font-weight: $metanavigation-action-font-weight !default;
$metanavigation-action-hover-font-size: $metanavigation-action-font-size !default;

$metanavigation-action-active-font-weight: $font-weight-bold !default;
$metanavigation-action-active-font-size: $metanavigation-action-font-size !default;

$metanavigation-languageswitch-padding: $dropdown-item-padding-y ($dropdown-item-padding-x * 0.5) !default;
$metanavigation-languageswitch-font-size: $font-size-sm !default;

$metanavigation-userflyout-padding: $dropdown-padding-y $dropdown-padding-x !default;
$metanavigation-userflyout-bg: $metanavigation-privat-bg !default;
$metanavigation-userflyout-box-shadow: $box-shadow !default;
$metanavigation-userflyout-border-radius: $metanavigation-border-radius !default;
$metanavigation-userflyout-transition-in: transform cubic-bezier(0.35, 0.05, 0.4, 1.5) 300ms, opacity ease-in 250ms 50ms !default;
$metanavigation-userflyout-transition-out: transform cubic-bezier(0.35, 0.05, 0.4, 1) 150ms, opacity ease-out 100ms !default;

$metanavigation-userflyout-button-color: $metanavigation-privat-action-color !default;
$metanavigation-userflyout-button-icon-color: lighten($metanavigation-privat-action-color, 25%) !default;
$metanavigation-userflyout-button-hover-color: $metanavigation-privat-action-hover-color !default;

$metanavigation-userflyout-item-font-size: $metanavigation-languageswitch-font-size !default;
$metanavigation-userflyout-item-color: $metanavigation-privat-action-color !default;

$metanavigation-userflyout-item-hover-bg: darken($metanavigation-userflyout-bg, 8%) !default;
$metanavigation-userflyout-item-hover-color: $white !default;

$metanavigation-userflyout-item-active-bg: darken($metanavigation-userflyout-bg, 4%) !default;
$metanavigation-userflyout-item-active-color: $metanavigation-userflyout-item-hover-color !default;

$metanavigation-userflyout-divider-bg: darken($metanavigation-userflyout-bg, 6%) !default;

// mobile
$metanavigation-mobile-breakpoint: $mobile-breakpoint !default;

$metanavigation-mobile-padding-y: $spacer * 1.5 !default;
$metanavigation-mobile-padding-x: $spacer * 1.5 !default;

$metanavigation-mobile-public-bg: $metanavigation-public-bg !default;
$metanavigation-mobile-public-action-font-size: $font-size-sm !default;
$metanavigation-mobile-public-action-color: $metanavigation-public-action-color !default;
$metanavigation-mobile-public-action-hover-color: $metanavigation-public-action-hover-color !default;

$metanavigation-mobile-privat-bg: $metanavigation-privat-bg !default;
$metanavigation-mobile-privat-action-font-size: 1em !default;
$metanavigation-mobile-privat-action-color: $metanavigation-privat-action-color !default;
$metanavigation-mobile-privat-action-hover-color: $metanavigation-privat-action-hover-color !default;

$metanavigation-mobile-action-padding-y: $spacer * 0.8 !default;
$metanavigation-mobile-action-padding-x: 0 !default;
$metanavigation-mobile-action-active-font-weight: $metanavigation-action-active-font-weight !default;

$metanavigation-mobile-languageswitch-item-padding-y: $spacer * 0.25 !default;
$metanavigation-mobile-languageswitch-item-padding-x: $spacer * 0.4 !default;

$metanavigation-mobile-userflyout-button-color: $metanavigation-userflyout-button-color !default;
$metanavigation-mobile-userflyout-button-icon-color: $metanavigation-userflyout-button-icon-color !default;

$metanavigation-mobile-userflyout-menu-padding-y: 0 !default;
$metanavigation-mobile-userflyout-menu-padding-x: $metanavigation-mobile-padding-x !default;

$metanavigation-mobile-userflyout-item-padding-y: $metanavigation-mobile-action-padding-y * 0.5 !default;
$metanavigation-mobile-userflyout-item-padding-x: $metanavigation-mobile-action-padding-x !default;
$metanavigation-mobile-userflyout-item-font-size: $metanavigation-mobile-privat-action-font-size * 0.8 !default;
$metanavigation-mobile-userflyout-item-color: $metanavigation-mobile-privat-action-color !default;

$metanavigation-mobile-userflyout-item-hover-bg: $metanavigation-userflyout-item-hover-bg !default;
$metanavigation-mobile-userflyout-item-hover-color: $metanavigation-userflyout-item-hover-color !default;

$metanavigation-mobile-userflyout-item-active-bg: $metanavigation-userflyout-item-active-bg !default;
$metanavigation-mobile-userflyout-item-active-color: $metanavigation-userflyout-item-active-color !default;

$metanavigation-mobile-userflyout-divider-bg: $metanavigation-userflyout-divider-bg !default;

.metanavigation {
  a {
    text-decoration: none;
  }

  ul:not([class]) {
    margin: 0;
    @include list-unstyled();
  }

  &:not(.is-mobile) {
    display: flex;
    align-items: stretch;
    position: relative;
    height: $metanavigation-height;
    z-index: 2;

    ul:not([class]) {
      display: flex;
    }

    .meta-action {
      display: block;
      padding: $metanavigation-action-padding-y $metanavigation-action-padding-x;
      font-weight: $metanavigation-action-font-weight;
      font-size: $metanavigation-action-font-size;

      &:hover {
        font-weight: $metanavigation-action-hover-font-weight;
        font-size: $metanavigation-action-hover-font-size;
      }

      &.active {
        font-weight: $metanavigation-action-active-font-weight;
        font-size: $metanavigation-action-active-font-size;
      }

      &.languageswitch {
        .flyout-menu {
          min-width: 0;

          .flyout-item {
            .flyout-item-link,
            .flyout-item-action > * {
              padding: $metanavigation-languageswitch-padding;
              font-size: $metanavigation-languageswitch-font-size;
              text-align: center;
            }
          }
        }
      }
    }

    .metanavigation-public {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      flex: 1 0 auto;
      position: relative;
      padding: $metanavigation-padding-y ($metanavigation-padding-x * 0.5) $metanavigation-padding-y $metanavigation-padding-x;
      background-color: $metanavigation-public-bg;
      border-bottom-left-radius: $metanavigation-border-radius;

      .meta-action {
        display: block;
        color: $metanavigation-public-action-color;

        &:hover {
          color: $metanavigation-public-action-hover-color;
        }
      }
    }

    .metanavigation-privat {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      position: relative;
      padding: $metanavigation-padding-y $metanavigation-padding-x $metanavigation-padding-y ($metanavigation-padding-x * 0.5);
      background-color: $metanavigation-privat-bg;
      border-bottom-right-radius: $metanavigation-border-radius;
      z-index: 1;

      .meta-action {
        color: $metanavigation-privat-action-color;

        &:hover {
          color: $metanavigation-privat-action-hover-color;
        }
      }

      .custom-flyout {
        .flyout-button {
          display: block;
          position: relative;
          z-index: 1;
          color: $metanavigation-userflyout-button-color;

          .bi {
            color: $metanavigation-userflyout-button-icon-color;
          }

          .button-icon {
            display: none;
          }
        }

        .flyout-menu {
          overflow: hidden;
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          margin: 0;
          padding: $metanavigation-userflyout-padding;
          background-color: $metanavigation-userflyout-bg;
          box-shadow: $metanavigation-userflyout-box-shadow;
          border-bottom-right-radius: $metanavigation-userflyout-border-radius;
          z-index: -1;

          &:before {
            display: block;
            content: '';
            height: $metanavigation-height;
          }

          .flyout-item {
            .flyout-item-link,
            .flyout-item-action > * {
              font-size: $metanavigation-userflyout-item-font-size;
              color: $metanavigation-userflyout-item-color;

              &:hover {
                background-color: $metanavigation-userflyout-item-hover-bg;
                color: $metanavigation-userflyout-item-hover-color;
              }
            }

            .flyout-item-action {
              border-left: $hr-border-width solid $metanavigation-userflyout-divider-bg;
            }

            &.active {
              .flyout-item-link,
              .flyout-item-action > * {
                background-color: $metanavigation-userflyout-item-active-bg;
                color: $metanavigation-userflyout-item-active-color;

                &:hover {
                  background-color: $metanavigation-userflyout-item-hover-bg;
                  color: $metanavigation-userflyout-item-hover-color;
                }
              }
            }
          }

          .flyout-divider {
            background-color: $metanavigation-userflyout-divider-bg;
          }

          &.flyout-enter {
            opacity: 0;
            transform: translateY(-50%);
          }

          &.flyout-enter-active {
            transition: $metanavigation-userflyout-transition-in;
          }

          &.flyout-enter-to {
            opacity: 1;
            transform: translateY(0%);
            transform: translateY(0%);
          }

          &.flyout-leave {
            opacity: 1;
            transform: translateY(0%);
          }

          &.flyout-leave-active {
            transition: $metanavigation-userflyout-transition-out;
          }

          &.flyout-leave-to {
            opacity: 0;
            transform: translateY(-50%);
          }
        }

        &:hover {
          .flyout-button {
            color: $metanavigation-userflyout-button-hover-color;
          }
        }

        &.is-showing {
          .flyout-button {
            color: $metanavigation-userflyout-button-hover-color;
          }
        }
      }

      .user {
        display: flex;
      }

      .hotline {}

      .cart {}

      > * {
        + * {
          border-left: $border-width solid $metanavigation-privat-action-color;
        }
      }
    }

    @include media-breakpoint-down($metanavigation-mobile-breakpoint) {
      display: none;
      height: 0;
    }
  }

  &.is-mobile {
    display: none;

    @include media-breakpoint-down($metanavigation-mobile-breakpoint) {
      display: block;
      border-top: 2px solid $primary;

      .meta-action {
        &.active {
          font-weight: $metanavigation-mobile-action-active-font-weight;
          color: inherit!important;
          cursor: pointer;
        }

        &.languageswitch {
          .flyout-button {
            display: none;
          }

          .flyout-menu {
            display: flex;
            flex-wrap: wrap;
            position: static;
            padding: 0;
            background-color: transparent;
            font-size: inherit;
            transform: none;
            box-shadow: none;

            &:before {
              display: none;
            }

            .flyout-item {
              .flyout-item-link,
              .flyout-item-action > * {
                padding: $metanavigation-mobile-languageswitch-item-padding-y $metanavigation-mobile-languageswitch-item-padding-x;
              }
            }
          }
        }
      }

      .metanavigation-public,
      .metanavigation-privat {
        position: relative;
        padding: $metanavigation-mobile-padding-y $metanavigation-mobile-padding-x;
        @include make-container();
        @include make-container-max-widths();

        .meta-action {
          display: inline-block;
          padding: $metanavigation-mobile-action-padding-y $metanavigation-mobile-action-padding-x;

          &:first-child {
            margin-top: $metanavigation-mobile-action-padding-y * -1;
          }

          &:last-child {
            margin-bottom: $metanavigation-mobile-action-padding-y * -1;
          }
        }

        &:before,
        &:after {
          display: block;
          content: '';
          position: absolute;
          top: 0;
          bottom: 0;
          width: 50vw;
          background-color: inherit;
        }

        &:before {
          right: 100%;
        }

        &:after {
          left: 100%;
        }
      }

      .metanavigation-public {
        background-color: $metanavigation-mobile-public-bg;

        .meta-action {
          font-size: $metanavigation-mobile-public-action-font-size;
          color: $metanavigation-mobile-public-action-color;

          &:hover {
            color: $metanavigation-mobile-public-action-hover-color;
          }
        }
      }

      .metanavigation-privat {
        background-color: $metanavigation-mobile-privat-bg;

        .meta-action {
          font-size: $metanavigation-mobile-privat-action-font-size;
          color: $metanavigation-mobile-privat-action-color;

          &:hover {
            color: $metanavigation-mobile-privat-action-hover-color;
          }
        }

        .custom-flyout {
          display: block;

          .flyout-button {
            color: $metanavigation-mobile-userflyout-button-color;
            cursor: default;

            .bi {
              color: $metanavigation-mobile-userflyout-button-icon-color;
            }

            .button-icon {
              display: none;
            }
          }

          .flyout-menu {
            margin: 0;
            padding: $metanavigation-mobile-userflyout-menu-padding-y $metanavigation-mobile-userflyout-menu-padding-x;

            .flyout-item {
              .flyout-item-link,
              .flyout-item-action > * {
                background-color: transparent;
                font-size: $metanavigation-mobile-userflyout-item-font-size;
                color: $metanavigation-mobile-userflyout-item-color;

                &:hover {
                  background-color: $metanavigation-mobile-userflyout-item-hover-bg;
                  color: $metanavigation-mobile-userflyout-item-hover-color;
                }
              }

              .flyout-item-link {
                padding-left: 0;
              }

              .flyout-item-action {
                border-left: $hr-border-width solid $metanavigation-mobile-userflyout-divider-bg;
              }

              &.active {
                .flyout-item-link,
                .flyout-item-action > * {
                  background-color: $metanavigation-mobile-userflyout-item-active-bg;
                  color: $metanavigation-mobile-userflyout-item-active-color;

                  &:hover {
                    background-color: $metanavigation-mobile-userflyout-item-hover-bg;
                    color: $metanavigation-mobile-userflyout-item-hover-color;
                  }
                }
              }
            }
          }

          .flyout-divider {
            background-color: $metanavigation-mobile-userflyout-divider-bg;
          }
        }

        .user {}

        .hotline {}

        .cart {}
      }
    }
  }
}
</style>
